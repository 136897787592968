var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-0", attrs: { cols: "auto" } },
    [
      _vm.videoLinks.length > 0
        ? _c(
            "v-menu",
            {
              staticClass: "pt-10",
              attrs: {
                "close-delay": "120",
                transition: "slide-x-transition",
                bottom: "",
                left: "",
                "offset-y": "",
                "nudge-right": "5",
                "close-on-content-click": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  icon: "",
                                  color: "blue-grey lighten-3"
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-help-rhombus-outline")])],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                142351346
              )
            },
            [
              _c(
                "v-card",
                { attrs: { "max-width": "220", tile: "" } },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "", dense: "" } },
                    [
                      _c("v-subheader", [_vm._v("Tutorial Videos")]),
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.videoLinks, function(link, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function($event) {
                                  return _vm.openVideoPlayer(link.id)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(link.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showVideoModal
        ? _c("VideoPlayer", {
            attrs: { id: _vm.videoId },
            on: { close: _vm.closeVideoPlayer },
            model: {
              value: _vm.showVideoModal,
              callback: function($$v) {
                _vm.showVideoModal = $$v
              },
              expression: "showVideoModal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }