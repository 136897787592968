<template>
  <v-col cols="auto" class="pa-0">
    <v-menu
      v-if="videoLinks.length > 0"
      class="pt-10"
      close-delay="120"
      transition="slide-x-transition"
      bottom
      left
      offset-y
      nudge-right="5"
      :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="blue-grey lighten-3"
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-help-rhombus-outline</v-icon>
        </v-btn>
      </template>
      <v-card max-width="220" tile>
        <v-list nav dense>
          <v-subheader>Tutorial Videos</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(link, i) in videoLinks"
              :key="i"
              @click="openVideoPlayer(link.id)">
              {{ link.label }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <VideoPlayer
      v-if="showVideoModal"
      v-model="showVideoModal"
      :id="videoId"
      @close="closeVideoPlayer">
    </VideoPlayer>
  </v-col>
</template>
<script>
const VideoPlayer = () => import('@/components/VideoPlayer.vue')
export default {
  data () {
    return {
      showVideoModal: false,
      videoId: null,
      videoLinks: [
        {
          id: '208a3bc5-726a-4864-96b0-8ed98ae1bd91',
          label: 'Intro to Promotions'
        },
        {
          id: '5a7ba98a-3090-4ce6-b5a9-262588ab1514',
          label: 'Creating a Promotion'
        },
        {
          id: '3ffb6602-99cf-47fb-bdb8-42098f4675da',
          label: 'Adding Promotion Groups'
        },
        {
          id: 'e3ae4956-178b-4eca-8ef1-1d62d46b7e1c',
          label: 'Adding Promotion Items'
        }
      ]
    }
  },
  name: 'VideoMenu',
  components: { VideoPlayer },
  methods: {
    openVideoPlayer (id) {
      this.videoId = id
      this.showVideoModal = true
    },
    closeVideoPlayer () {
      this.showVideoModal = false
      this.videoId = null
    }
  }
}
</script>